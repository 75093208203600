import { IDraftedPairs } from '../../../store/reducers/PairingReducer';

export const getUniqueDraftedUsers = (list: IDraftedPairs[]): number => {
  const users: string[] = [];
  list?.forEach((d) => {
    if (!users.includes(d.mentee)) {
      users.push(d.mentee);
    }
    if (!users.includes(d.mentor)) {
      users.push(d.mentor);
    }
  });
  return users.length;
};
