import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircle from '@material-ui/icons/AddCircle';
import { useDispatch, useSelector } from 'react-redux';
import { ActionButton } from '../../Components/InputFields/BrancherButton';
import {
  IProgram,
  programConfig,
  subscriptionConfig,
  Subscriptions,
} from '../../store/reducers/ProgramReducer';
import { PlatformTypes } from '../../types/PlatformTypes';
import {
  UtilCreateProgram,
  BrancherDispatch,
  UtilGetPrevProgramInformation,
} from '../../store/actions';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { BrancherCheckbox } from '../../Components/InputFields/BrancherCheckbox';
import { BrancherRadio } from '../../Components/InputFields/Radio/BrancherRadio';
import { IStoreTypes } from '../../store/storeTypes';
import { Text } from '../../Components/General/Text';
import { BrancherSelect } from '../../Components/InputFields/BrancherSelect';
import { add, format } from 'date-fns';
import { BrancherInputLabel } from '../../Components/InputFields/BrancherInputLabel';
import { DatePicker } from '../../Components/InputFields/DatePicker';
import { dateDefaultFormat } from '../../helpers/DateHelpers';

export enum EBooleanOptions {
  YES = 'YES',
  NO = 'NO',
}

interface ICreateProgram {
  closeProgramTile: () => void;
}

export const CreateProgram: React.FC<ICreateProgram> = ({ closeProgramTile }) => {
  const [programName, setProgramName] = React.useState<string>();
  const [supportEmail, setSupportEmail] = React.useState<string>();
  const [subscriptions, setSubscriptions] = React.useState<Subscriptions[]>([
    Subscriptions.FORM,
    Subscriptions.TRAINING,
    Subscriptions.COHORT,
    Subscriptions.PROFILE,
    Subscriptions.SHARED_NOTES,
    Subscriptions.PRIVATE_NOTES,
    Subscriptions.MEETINGS,
  ]);
  const [programType, setProgramType] = React.useState<PlatformTypes>(PlatformTypes.mentoring);
  const [programLengthMonths, setProgramLengthMonths] = React.useState<number>(10);
  const [creatingProgram, setCreatingProgram] = React.useState<boolean>(false);
  const [hasPlatformAccess, setHasPlatformAccess] = React.useState<EBooleanOptions>(
    EBooleanOptions.NO,
  );
  const [applicationAlwaysOpen, setApplicationAlwaysOpen] = React.useState<EBooleanOptions>(
    EBooleanOptions.NO,
  );
  const [gettingDuplicateProgramData, setGettingDuplicateProgramData] =
    React.useState<boolean>(false);
  const [hasDuplicateProgram, setHasDuplicateProgram] = React.useState<EBooleanOptions>(
    EBooleanOptions.NO,
  );
  const [copyOverMentors, setCopyOverMentors] = React.useState<EBooleanOptions>(EBooleanOptions.NO);
  const [copyOverMentees, setCopyOverMentees] = React.useState<EBooleanOptions>(EBooleanOptions.NO);
  const [maximumUserCapHard, setMaximumUserCapHard] = React.useState<number>(120);
  const [maximumUserCapSoft, setMaximumUserCapSoft] = React.useState<number>(120);
  const [subscriptionEndDate, setSubscriptionEndDate] = React.useState<Date>(
    add(new Date(), { months: 12 }),
  );
  const [duplicateProgram, setDuplicateProgram] = React.useState<string>();
  const companyId = useSelector((state: IStoreTypes) => state.user?.selectedCompanyId);
  const programs = useSelector((state: IStoreTypes) => state.companies?.programs);
  const dispatch = useDispatch();

  const controlSetDuplicateProgram = (dupeProgram: string) => {
    setDuplicateProgram(dupeProgram);
    setGettingDuplicateProgramData(true);
    BrancherDispatch(
      dispatch,
      UtilGetPrevProgramInformation(dupeProgram, (programData: IProgram) => {
        setProgramLengthMonths(programData.programLengthMonths);
        setSubscriptions(programData.subscription);
        setSupportEmail(programData.supportEmail);
        setProgramType(programData.programType);
        setGettingDuplicateProgramData(false);
      }),
    );
  };

  const addSubscription = (sub: Subscriptions) => {
    setSubscriptions(subscriptions.concat(sub));
  };

  const removeSubscription = (sub: Subscriptions) => {
    setSubscriptions(subscriptions.filter((s) => s !== sub));
  };

  const createProgram = () => {
    setCreatingProgram(true);
    BrancherDispatch(
      dispatch,
      UtilCreateProgram(
        programName,
        programType,
        subscriptions,
        programLengthMonths,
        supportEmail,
        hasPlatformAccess === EBooleanOptions.YES,
        duplicateProgram,
        applicationAlwaysOpen === EBooleanOptions.YES,
        copyOverMentors === EBooleanOptions.YES,
        copyOverMentees === EBooleanOptions.YES,
        maximumUserCapHard,
        maximumUserCapSoft,
        format(new Date(subscriptionEndDate), dateDefaultFormat),
        () => {
          setProgramName('');
          setDuplicateProgram('');
          setHasDuplicateProgram(EBooleanOptions.NO);
          setCopyOverMentors(EBooleanOptions.NO);
          setCopyOverMentees(EBooleanOptions.NO);
          setHasPlatformAccess(EBooleanOptions.NO);
          setApplicationAlwaysOpen(EBooleanOptions.NO);
          setSubscriptionEndDate(add(new Date(), { months: 12 }));
          setMaximumUserCapSoft(120);
          setMaximumUserCapHard(120);
          setProgramLengthMonths(10);
          setSupportEmail('');
          setCreatingProgram(false);
          closeProgramTile();
        },
      ),
    );
  };

  const validateProgramData = (): boolean => {
    return (
      !!programName &&
      !!programType &&
      programLengthMonths >= 6 &&
      programLengthMonths <= 12 &&
      subscriptions?.length > 0 &&
      !!companyId &&
      !!supportEmail
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Text variant="md" fontWeight={600}>
          Create New Program
        </Text>
      </Grid>
      <Grid item xs={12}>
        <BrancherTextField
          value={programName}
          updateValue={setProgramName}
          placeholder="New program"
          label="Program Name"
          fullWidth
        />
      </Grid>
      {programs?.length > 0 && (
        <>
          <Grid item xs={12}>
            <BrancherRadio
              aria-label="has-duplicate-program"
              value={hasDuplicateProgram}
              onChange={(a: EBooleanOptions) => {
                setHasDuplicateProgram(a);
                if (a === EBooleanOptions.YES) {
                  setDuplicateProgram('');
                }
              }}
              label="Do you want to duplicate a previous program?"
              options={[
                { label: 'No', value: EBooleanOptions.NO },
                { label: 'Yes', value: EBooleanOptions.YES },
              ]}
            />
          </Grid>
          {hasDuplicateProgram === EBooleanOptions.YES && (
            <>
              <Grid item xs={12}>
                <BrancherSelect
                  name="duplicateProgram"
                  value={duplicateProgram}
                  updateValue={(e: string) => controlSetDuplicateProgram(e)}
                  label="Duplicate program"
                  options={programs.map((m) => ({ label: m.programName, value: m.programId }))}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <BrancherCheckbox
                  updateValue={(s) =>
                    setCopyOverMentors(!!s ? EBooleanOptions.YES : EBooleanOptions.NO)
                  }
                  value={copyOverMentors === EBooleanOptions.YES}
                  label="Copy program's mentors' previous responses"
                />
              </Grid>
              <Grid item xs={12}>
                <BrancherCheckbox
                  updateValue={(s) =>
                    setCopyOverMentees(!!s ? EBooleanOptions.YES : EBooleanOptions.NO)
                  }
                  value={copyOverMentees === EBooleanOptions.YES}
                  label="Copy program's mentees' previous responses"
                />
              </Grid>
            </>
          )}
        </>
      )}

      {!gettingDuplicateProgramData ? (
        <>
          <Grid item xs={12}>
            <BrancherTextField
              value={String(programLengthMonths)}
              updateValue={(v) => setProgramLengthMonths(Number(v))}
              type="number"
              fullWidth
              min={6}
              max={12}
              placeholder="Program length in months"
              label="Program Length (Months)"
            />
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between" item xs={12}>
            <Grid item>
              <BrancherInputLabel variant="sm" fontWeight={700} color="purple" for="date">
                Subscription end date
              </BrancherInputLabel>
              <DatePicker
                value={subscriptionEndDate}
                updateValue={setSubscriptionEndDate}
                minDate={add(new Date(), { months: 3 })}
                id="subscription-end-date"
                name="subscription-end-date"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BrancherTextField
              value={supportEmail}
              updateValue={setSupportEmail}
              fullWidth
              placeholder="Program support lead email"
              label="Program support email"
            />
          </Grid>
          <Grid item xs={12}>
            <BrancherTextField
              value={maximumUserCapHard}
              updateValue={(cap) => setMaximumUserCapHard(Number(cap))}
              fullWidth
              type="number"
              placeholder="Maximum amount of users allowed in program"
              label="Hard maximum amount of users in program"
            />
          </Grid>
          <Grid item xs={12}>
            <BrancherTextField
              value={maximumUserCapSoft}
              updateValue={(cap) => setMaximumUserCapSoft(Number(cap))}
              fullWidth
              type="number"
              placeholder="Amount of users allowed in program before a warning is shown"
              label="Soft maximum amount of users in program"
            />
          </Grid>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
              <Text variant="sm" fontWeight={600}>
                Subscriptions
              </Text>
            </Grid>
            {subscriptionConfig.map((sub, i) => (
              <Grid item xs={12} md={6} key={i}>
                <BrancherCheckbox
                  updateValue={(s) => {
                    if (s) {
                      addSubscription(sub.value);
                    } else {
                      removeSubscription(sub.value);
                    }
                  }}
                  value={subscriptions?.includes(sub.value)}
                  label={sub.label}
                />
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <BrancherRadio
              options={[
                { label: 'No', value: EBooleanOptions.NO },
                { label: 'Yes', value: EBooleanOptions.YES },
              ]}
              value={hasPlatformAccess}
              label="Has platform access"
              aria-label="has-platform-access"
              onChange={setHasPlatformAccess}
            />
          </Grid>
          <Grid item xs={12}>
            <BrancherRadio
              options={[
                { label: 'No', value: EBooleanOptions.NO },
                { label: 'Yes', value: EBooleanOptions.YES },
              ]}
              value={applicationAlwaysOpen}
              label="Application form always open"
              aria-label="application-always-open"
              onChange={setApplicationAlwaysOpen}
            />
          </Grid>
          <Grid item xs={12}>
            <BrancherRadio
              options={programConfig}
              value={programType}
              label="Program type"
              aria-label="program-type"
              onChange={setProgramType}
            />
          </Grid>
          <Grid item xs={12}>
            <ActionButton
              startIcon={<AddCircle color="secondary" />}
              onClick={createProgram}
              loading={creatingProgram}
              disabled={!validateProgramData()}
            >
              Create Program
            </ActionButton>
          </Grid>
        </>
      ) : (
        <CircularProgress color="secondary" size={64} />
      )}
    </Grid>
  );
};
