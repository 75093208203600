import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { Page } from '../../Components/General/Page';
import { BrancherDispatch, UtilGetAllProgramUsers } from '../../store/actions';
import { Colors } from '../../consts/colors';
import { RouteMaster } from '../../Components/Routing';
import { PairingReport } from '../Reports/PairingReport';
import { IStoreTypes } from '../../store/storeTypes';
import { BrancherSelect } from '../../Components/InputFields/BrancherSelect';
import { EGenerateReport } from '../../store/reducers/ReportReducer';
import { UserReport } from '../Reports/UserReport';
import { TrainingReport } from '../Reports/TrainingReport';
import { ActionOutlinedButton, BrancherButton } from '../../Components/InputFields/BrancherButton';
import { ProfileReport } from '../Reports/ProfileReport';
import { AllPositions } from '../../consts/ProgramPositionOptions';
import { PersonalityValuesReport } from '../Reports/PersonalityValuesReport';
import { IPairsInfo } from '../../store/reducers/PairingReducer';
import { EApplicationStatus, IUserGroups } from '../../store/reducers/UserInfoReducer';
import { PairingSatisfactionReport } from '../Reports/PairingSatisfactionReport';
import { UserDeactivation } from '../User/UserDeactivation';
import { Subscriptions, UpdatableProgramInfo } from '../../store/reducers/ProgramReducer';
import { Text } from '../../Components/General/Text';
import { UserApproval } from '../User/UserApproval';

interface ICustomToolbar {
  clearFilters: () => void;
}

const CustomToolbar: React.FC<ICustomToolbar> = ({ clearFilters }) => {
  const program = useSelector((state: IStoreTypes) => state.program);
  const isBrancherAdmin = useSelector((state: IStoreTypes) =>
    state.user?.positions?.includes(AllPositions.BRANCHER_ADMIN),
  );
  const [selectedReport, setSelectedReport] = React.useState<EGenerateReport>(
    EGenerateReport.USER_REPORT,
  );
  const [updateReport, setUpdateReport] = React.useState<boolean>(false);
  const [gettingReportData, setGettingReportData] = React.useState<boolean>(false);
  const [hasSpecificReportData, setHasSpecificReportData] = React.useState<boolean>(false);
  const hasPairs = (!program?.active && program?.menteeAmount > 0) || program?.launchedProgram;

  const baseReports = [
    { label: 'Users', value: EGenerateReport.USER_REPORT },
    { label: 'Pairing', value: EGenerateReport.PAIRING_REPORT },
    { label: 'Training', value: EGenerateReport.ALL_TRAINING_REPORT },
    { label: 'Profile', value: EGenerateReport.PROFILE_REPORT },
  ];

  const brancherAdminReports = [
    { label: 'Personality/Values', value: EGenerateReport.PERSONALITY_VALUES_REPORT },
    { label: 'Pairing Satisfaction', value: EGenerateReport.PAIRING_SATISFACTION_REPORT },
  ];

  const reports = baseReports;

  if (isBrancherAdmin) {
    reports.push(...brancherAdminReports);
  }

  const reportsAvailable: { label: string; value: EGenerateReport }[] = hasPairs
    ? reports
    : reports.filter((r) => r.value === EGenerateReport.USER_REPORT);

  const reportToGenerate: React.ReactElement =
    selectedReport === EGenerateReport.USER_REPORT ? (
      <UserReport
        controlled
        completedCallback={() => {
          setGettingReportData(false);
          setHasSpecificReportData(true);
        }}
      />
    ) : selectedReport === EGenerateReport.PAIRING_REPORT ? (
      <PairingReport
        controlled
        completedCallback={() => {
          setGettingReportData(false);
          setHasSpecificReportData(true);
        }}
      />
    ) : selectedReport === EGenerateReport.ALL_TRAINING_REPORT ? (
      <TrainingReport
        controlled
        completedCallback={() => {
          setGettingReportData(false);
          setHasSpecificReportData(true);
        }}
      />
    ) : selectedReport === EGenerateReport.PERSONALITY_VALUES_REPORT ? (
      <PersonalityValuesReport
        controlled
        completedCallback={() => {
          setGettingReportData(false);
          setHasSpecificReportData(true);
        }}
      />
    ) : selectedReport === EGenerateReport.PAIRING_SATISFACTION_REPORT ? (
      <PairingSatisfactionReport
        controlled
        completedCallback={() => {
          setGettingReportData(false);
          setHasSpecificReportData(true);
        }}
      />
    ) : (
      <ProfileReport
        controlled
        completedCallback={() => {
          setGettingReportData(false);
          setHasSpecificReportData(true);
        }}
      />
    );

  return (
    <GridToolbarContainer>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={4}>
          <GridToolbarDensitySelector />
        </Grid>
        <Grid item xs={7} container alignItems="center" justifyContent="flex-end" spacing={1}>
          <Grid item>
            <BrancherButton
              color="secondary"
              variant="outlined"
              onClick={clearFilters}
              size="small"
            >
              Clear Filters
            </BrancherButton>
          </Grid>
          <Grid item xs={4}>
            <BrancherSelect
              label="Reports"
              options={reportsAvailable}
              value={selectedReport}
              name="generate-report"
              updateValue={(r: EGenerateReport) => {
                setSelectedReport(r);
                setUpdateReport(false);
                setHasSpecificReportData(false);
              }}
            />
          </Grid>
          {(selectedReport || gettingReportData) && !hasSpecificReportData && (
            <Grid item>
              <ActionOutlinedButton
                onClick={() => {
                  setUpdateReport(true);
                  setGettingReportData(true);
                }}
                size="small"
                loading={gettingReportData}
              >
                Generate report
              </ActionOutlinedButton>
            </Grid>
          )}
          {selectedReport && updateReport && (
            <Box display={gettingReportData ? 'none' : 'flex'} width="min-content">
              {reportToGenerate}
            </Box>
          )}
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

interface IUserProfileLink {
  userId: string;
}

export const UserProfileLink: React.FC<IUserProfileLink> = ({ userId, children }) => (
  <Link to={`${RouteMaster.user.path}?u=${userId}`}>{children}</Link>
);

export enum BooleanTexts {
  YES = 'Yes',
  NO = 'No',
}

export interface IAllProgramUserData {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  menteeRoleId: string;
  mentorRoleId: string;
  mentees: string;
  menteesRaw: Array<Pick<IPairsInfo, 'name' | 'roleId'>>;
  mentors: string;
  mentorsRaw: Array<Pick<IPairsInfo, 'name' | 'roleId'>>;
  menteeAmt: number;
  mentorAmt: number;
  lastLoggedIn: string;
  lastLoggedInRaw: number[];
  daysSinceLastLogin: string;
  phoneNumber: string;
  deactivated: BooleanTexts;
  programId: string;
  hasStartedMenteePosition: boolean;
  hasStartedMentorPosition: boolean;
  hasMenteePosition: boolean;
  hasMentorPosition: boolean;
  isApprovedForProgram: boolean;
  applicationStatus: EApplicationStatus;
  menteeApplicationSubmitted: boolean;
  mentorApplicationSubmitted: boolean;
  hasGoogleCalendar: BooleanTexts;
  hasOutlookCalendar: BooleanTexts;
  needsReviewing: BooleanTexts;
  requestAmountToAction: number;
  isMentee: boolean;
  isMentor: boolean;
  menteeGroups: IUserGroups[];
  mentorGroups: IUserGroups[];
}

const useStyles = makeStyles({
  dataGrid: {
    width: '100%',
    minWidth: '100%',
    height: '100vh',
    '& .MuiSelect-select': {
      boxShadow: 'none !important',
      '&:focus': {
        color: `${Colors.black} !important`,
        backgroundColor: `${Colors.transparent} !important`,
      },
    },
  },
});

export const ProgramUsers = () => {
  const [gettingProgramUsers, setGettingProgramUsers] = React.useState<boolean>(false);
  const [userProgramData, setUserProgramData] = React.useState<IAllProgramUserData[]>();
  const requiresApproval = useSelector(
    (state: IStoreTypes) => state.program?.[UpdatableProgramInfo.REQUIRES_APPLICATION_APPROVAL],
  );
  const hasGroups = useSelector((state: IStoreTypes) =>
    state.program?.[UpdatableProgramInfo.SUBSCRIPTION].includes(Subscriptions.GROUPS),
  );
  const showPending = new URLSearchParams(useLocation().search)?.get('sp') === 'true';
  const showUnmatchedMentees = new URLSearchParams(useLocation().search)?.get('ume') === 'true';
  const history = useHistory();
  const dispatch = useDispatch();
  const styles = useStyles();

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'First name',
      width: 150,
      sortable: true,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 150,
      sortable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
      sortable: true,
      renderCell: (grid) => (
        <UserProfileLink userId={String(grid.id)}>{grid.value}</UserProfileLink>
      ),
    },
    {
      field: 'mentees',
      headerName: 'Mentees',
      width: 140,
      sortable: false,
    },
    {
      field: 'mentors',
      headerName: 'Mentors',
      width: 140,
      sortable: false,
    },
    {
      field: 'lastLoggedIn',
      headerName: 'Last active',
      width: 140,
      sortable: false,
    },
    {
      field: 'groupAmount',
      headerName: 'Groups',
      width: 150,
      sortable: true,
      hide: !hasGroups,
    },
    {
      field: 'applicationStatus',
      headerName: 'Application status',
      width: 260,
      sortable: true,
      hide: !requiresApproval,
      renderCell: (grid) => {
        const needsReviewing = grid.row.needsReviewing === BooleanTexts.YES;
        const isApproved = grid.row.approved === BooleanTexts.YES;
        return (
          <>
            <Text variant="xxs" marginRight={10}>
              {grid.value}
            </Text>
            <UserApproval
              tableView
              userId={grid.row.id}
              name={grid.row.firstName}
              approved={isApproved}
              reviewed={!needsReviewing}
              actionApprovalCallback={(approved) => updateUserApproval(grid.row.id, approved)}
            />
          </>
        );
      },
    },
    {
      field: 'deactivated',
      headerName: 'Deactivated',
      width: 160,
      sortable: true,
      renderCell: (grid) => {
        const isDeactivated = grid.row.deactivated === BooleanTexts.YES;
        return (
          <>
            <Text variant="xxs" marginRight={10}>
              {grid.value}
            </Text>
            <UserDeactivation
              tableView
              userId={grid.row.id}
              name={grid.row.firstName}
              deactivated={isDeactivated}
              actionActivationCallback={(deactivated) =>
                updateUserDeactivation(grid.row.id, deactivated)
              }
            />
          </>
        );
      },
    },
  ];

  const updateUserApproval = (userId: string, approved: boolean) => {
    const adjustedProgramData = userProgramData.map((u) => {
      if (u.id === userId) {
        return {
          ...u,
          isApprovedForProgram: approved,
          needsReviewing: BooleanTexts.NO,
          applicationStatus: approved ? EApplicationStatus.APPROVED : EApplicationStatus.DECLINED,
        };
      } else {
        return u;
      }
    });
    setUserProgramData(adjustedProgramData);
  };

  const updateUserDeactivation = (userId: string, deactivated: boolean) => {
    const adjustedProgramData = userProgramData.map((u) => {
      if (u.id === userId) {
        return { ...u, deactivated: deactivated ? BooleanTexts.YES : BooleanTexts.NO };
      } else {
        return u;
      }
    });
    setUserProgramData(adjustedProgramData);
  };

  React.useEffect(() => {
    setGettingProgramUsers(true);
    BrancherDispatch(
      dispatch,
      UtilGetAllProgramUsers((userData) => {
        if (userData.success) {
          setUserProgramData(userData.data);
        }
        setGettingProgramUsers(false);
      }),
    );
  }, []);

  const modifiedUserData = userProgramData?.map((u) => u);

  const controlClearFilters = () => {
    history.replace({
      search: '',
    });
    window.location.reload();
  };

  const createInitialState = () => {
    return showPending
      ? {
          items: [
            {
              columnField: 'applicationStatus',
              operatorValue: 'equals',
              value: EApplicationStatus.PENDING,
            },
          ],
        }
      : {
          items: [
            {
              columnField: 'mentors',
              operatorValue: 'equals',
              value: 'None',
            },
          ],
        };
  };

  return (
    <Page loading={gettingProgramUsers}>
      <Grid container justifyContent="center">
        <Grid container item xs={12}>
          <Box className={styles.dataGrid}>
            {modifiedUserData?.length > 0 &&
              (showUnmatchedMentees ? (
                <DataGrid
                  rows={userProgramData}
                  columns={columns}
                  pageSize={30}
                  disableSelectionOnClick
                  // checkboxSelection
                  // onSelectionModelChange={(a) => console.log(a)}
                  components={{
                    Toolbar: () => <CustomToolbar clearFilters={controlClearFilters} />,
                  }}
                  filterModel={{ ...createInitialState() }}
                />
              ) : showPending ? (
                <DataGrid
                  rows={userProgramData}
                  columns={columns}
                  pageSize={30}
                  disableSelectionOnClick
                  // checkboxSelection
                  // onSelectionModelChange={(a) => console.log(a)}
                  components={{
                    Toolbar: () => <CustomToolbar clearFilters={controlClearFilters} />,
                  }}
                  filterModel={{ ...createInitialState() }}
                />
              ) : (
                <DataGrid
                  rows={userProgramData}
                  columns={columns}
                  pageSize={30}
                  disableSelectionOnClick
                  // checkboxSelection
                  // onSelectionModelChange={(a) => console.log(a)}
                  components={{
                    Toolbar: () => <CustomToolbar clearFilters={controlClearFilters} />,
                  }}
                />
              ))}
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};
