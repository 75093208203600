import * as React from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import {
  ActionNegativeOutlinedButton,
  ActionOutlinedButton,
  ActionPositiveOutlinedButton,
} from '../../Components/InputFields/BrancherButton';
import { BrancherSnackbar } from '../../Components/General/BrancherSnackbar';
import { Text } from '../../Components/General/Text';
import { BrancherDispatch, UtilSetUserApproval } from '../../store/actions';
import { ConfirmDialog } from '../../Components/General/ConfirmDialog';
import { BrancherCheckbox } from '../../Components/InputFields/BrancherCheckbox';

interface IUserApproval {
  userId: string;
  name: string;
  actionApprovalCallback?: (approved?: boolean) => void;
  reviewed?: boolean;
  approved?: boolean;
  tableView?: boolean;
}

export const UserApproval: React.FC<IUserApproval> = (props) => {
  const { userId, actionApprovalCallback, name, approved, reviewed, tableView } = props;
  const [settingUserApproval, setSettingUserApproval] = React.useState<boolean>(false);
  const [openApprovalMessage, setOpenApprovalMessage] = React.useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState<boolean>(false);
  const [sendNotification, setSendNotification] = React.useState<boolean>(false);
  const [approveUser, setApproveUser] = React.useState<boolean>(null);
  const [approvalMessage, setApprovalMessage] = React.useState<string>('');
  const dispatch = useDispatch();

  const controlUserApproval = (approve: boolean) => {
    setApproveUser(approve);
    setOpenConfirmDialog(true);
  };

  const setUserApproval = () => {
    // ensuring a value is selected - not null
    if (typeof approveUser == 'boolean') {
      setSettingUserApproval(true);
      BrancherDispatch(
        dispatch,
        UtilSetUserApproval(userId, approveUser, sendNotification, () => {
          // Update the row status here...
          actionApprovalCallback(approveUser);
          setSettingUserApproval(false);
          if (!approveUser) {
            const message = `Application has been rejected and an email has been sent to notify ${name}`;
            setApprovalMessage(message);
          } else {
            const message = `Application has been approved and an email has been sent to notify ${name}`;
            setApprovalMessage(message);
          }
          setApproveUser(null);
          setOpenApprovalMessage(true);
        }),
      );
    }
  };

  return (
    <>
      <BrancherSnackbar
        controlClose={setOpenApprovalMessage}
        open={openApprovalMessage}
        message={approvalMessage}
      />
      <ConfirmDialog
        setClose={() => setOpenConfirmDialog(false)}
        labelledBy="user-approval"
        open={openConfirmDialog}
        title={`${approveUser ? 'Approve' : 'Reject'} ${name}`}
        confirmAction={setUserApproval}
        confirmButtonText={`Yes, ${approveUser ? 'approve' : 'reject'}`}
        denyAction={() => setOpenConfirmDialog(false)}
        denyButtonText="Not yet"
      >
        <Grid container justify="center" alignItems="center" direction="column">
          <Grid item>
            <Text variant="md" fontWeight={600} marginTop={50}>
              Are you sure you want to {approveUser ? 'Approve' : 'Reject'} {name} for this program?
            </Text>
          </Grid>

          <Grid item>
            <Box display="flex" mb={5} mt={3} justifyContent="center">
              <BrancherCheckbox
                name="sendNotification"
                value={sendNotification}
                updateValue={setSendNotification}
                label={`Send an email notification to notify ${name}`}
              />
            </Box>
          </Grid>
        </Grid>
      </ConfirmDialog>
      {tableView ? (
        <Grid container item>
          {!reviewed && (
            <Grid container item spacing={1} alignItems="center">
              <Grid item>
                <Tooltip title={`Approve ${name}`}>
                  <Box>
                    <ActionPositiveOutlinedButton
                      onClick={() => controlUserApproval(true)}
                      disabled={settingUserApproval}
                      size="small"
                    >
                      Approve
                    </ActionPositiveOutlinedButton>
                  </Box>
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip title={`Reject ${name}`}>
                  <Box>
                    <ActionNegativeOutlinedButton
                      onClick={() => controlUserApproval(false)}
                      disabled={settingUserApproval}
                      size="small"
                    >
                      Reject
                    </ActionNegativeOutlinedButton>
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container item>
          {reviewed ? (
            <Grid item container justifyContent="flex-end">
              <Text variant="xs" fontWeight={500}>
                {name}'s application has been {approved ? 'approved' : 'rejected'}.
              </Text>
            </Grid>
          ) : (
            <Grid item container justifyContent="space-between" spacing={2}>
              <Grid item xs={6}>
                <ActionOutlinedButton
                  onClick={() => controlUserApproval(true)}
                  loading={settingUserApproval}
                >
                  Approve application
                </ActionOutlinedButton>
              </Grid>
              <Grid item xs={6}>
                <ActionNegativeOutlinedButton
                  onClick={() => controlUserApproval(false)}
                  loading={settingUserApproval}
                >
                  Reject application
                </ActionNegativeOutlinedButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};
