import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import { Text } from '../../../Components/General/Text';
import { ConfirmDialog } from '../../../Components/General/ConfirmDialog';
import { BrancherTextField } from '../../../Components/InputFields/BrancherTextField';
import { BrancherButton } from '../../../Components/InputFields/BrancherButton';
import { IDefaultValueType, IToggle } from '../../../store/reducers/ProgramReducer';

interface IQuestionAnswerOptions {
  options: IToggle['options'];
  setOptions: (newOpts: IToggle['options']) => void;
  selectedOptionIndex?: number; // This is the parent selected arrays index
  selectedSecondaryOptionIndex?: number; // This is the second selected arrays index
  defaultValue?: IDefaultValueType;
  setDefaultValue?: (val: IDefaultValueType) => void;
  isDependentQuestion?: boolean;
  hideDefaultSelect?: boolean;
}

export const QuestionAnswerOptions: React.FC<IQuestionAnswerOptions> = (props) => {
  const {
    options,
    setOptions,
    setDefaultValue,
    defaultValue,
    isDependentQuestion,
    selectedOptionIndex,
    selectedSecondaryOptionIndex,
    hideDefaultSelect,
  } = props;
  const [optionDialog, setOptionDialog] = React.useState<boolean>(false);
  const [newOptionText, setNewOptionText] = React.useState<string>('');

  const closeDialog = () => {
    setOptionDialog(false);
    setNewOptionText('');
  };

  const addAnswerOptions = () => {
    setOptions([...options, newOptionText] as IToggle['options']);
    closeDialog();
  };

  const removeOption = (optionIndex: number) => {
    // @ts-ignore
    const filteredOptions =
      isDependentQuestion && !selectedSecondaryOptionIndex
        ? // @ts-ignore
          (options?.filter((a, i) => i !== optionIndex) as IToggle['options'])
        : isDependentQuestion && selectedSecondaryOptionIndex
        ? // @ts-ignore
          (options[selectedOptionIndex][selectedSecondaryOptionIndex]?.filter(
            (a, i) => i !== optionIndex,
          ) as IToggle['options'])
        : // @ts-ignore
          (options[selectedOptionIndex]?.filter((a, i) => i !== optionIndex) as IToggle['options']);
    setOptions(filteredOptions);
  };

  return (
    <Grid container>
      <ConfirmDialog
        setClose={closeDialog}
        labelledBy="add-answer-option"
        open={optionDialog}
        title="Add option"
        confirmAction={addAnswerOptions}
        confirmButtonText="Add"
        denyAction={closeDialog}
        denyButtonText="Cancel"
      >
        <Box width="100%" mb={5}>
          <BrancherTextField
            value={newOptionText}
            updateValue={setNewOptionText}
            placeholder="New option..."
            fullWidth
          />
        </Box>
      </ConfirmDialog>
      {!isDependentQuestion && (
        <Grid item container xs={12}>
          <Text variant="md" fontWeight={600} color="purple" marginTop={30} marginBottom={10}>
            Answer options
          </Text>
        </Grid>
      )}
      <Grid item container xs={12} direction="column" spacing={1}>
        {options?.map((a, i) => (
          <Grid item key={i} container alignItems="center">
            <Text variant="sm" display="inline" marginRight={20}>
              {i + 1}. {a}
            </Text>
            <IconButton onClick={() => removeOption(i)} size="small">
              <Close color="error" fontSize="small" />
            </IconButton>
            {!hideDefaultSelect && !isDependentQuestion && i.toString() !== defaultValue && (
              <Box ml={2}>
                <BrancherButton onClick={() => setDefaultValue(i.toString())} size="small">
                  Set default
                </BrancherButton>
              </Box>
            )}
          </Grid>
        ))}
      </Grid>
      <Box mt={2}>
        <BrancherButton
          color="secondary"
          onClick={() => setOptionDialog(true)}
          startIcon={<Add />}
          size="small"
        >
          Add option
        </BrancherButton>
      </Box>
    </Grid>
  );
};
