import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { GenerateUserReport } from './GenerateUserReport';
import { IStoreTypes } from '../../store/storeTypes';
import { BrancherDispatch, UtilGenerateReport } from '../../store/actions';
import { ActionButton } from '../../Components/InputFields/BrancherButton';
import { EGenerateReport } from '../../store/reducers/ReportReducer';

interface IUserReport {
  controlled?: boolean;
  completedCallback?: () => void;
}

export const UserReport: React.FC<IUserReport> = ({ controlled, completedCallback }) => {
  const program = useSelector((state: IStoreTypes) => state.program);
  const userReportData = useSelector((state: IStoreTypes) => state?.report?.userReportData);
  const userReportColumns = useSelector((state: IStoreTypes) => state?.report?.userReportColumns);
  const [generatingUserReport, setGeneratingUserReport] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (controlled) {
      generateUserReport();
    }
  }, []);

  const generateUserReport = () => {
    setGeneratingUserReport(true);
    BrancherDispatch(
      dispatch,
      UtilGenerateReport(EGenerateReport.USER_REPORT, () => {
        setGeneratingUserReport(false);
        if (controlled) {
          completedCallback();
        }
      }),
    );
  };

  return (
    <Grid item xs={12} container alignItems="center" spacing={2}>
      {!controlled && (
        <Grid item xs={6}>
          <ActionButton onClick={generateUserReport} loading={generatingUserReport} size="small">
            Generate user report
          </ActionButton>
        </Grid>
      )}
      {userReportData && (
        <Grid item xs={6}>
          <GenerateUserReport
            data={userReportData}
            displayData={userReportColumns}
            programName={program.programName}
          />
        </Grid>
      )}
    </Grid>
  );
};
