import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  IActiveUsersData,
  IApplicationApprovalHealthData,
} from '../../store/reducers/DashboardReducer';
import { Text } from '../../Components/General/Text';
import { BrancherGridCard } from '../../Components/General/BrancherGridCard';
import { useSelector } from 'react-redux';
import { IStoreTypes } from '../../store/storeTypes';
import { UpdatableProgramInfo } from '../../store/reducers/ProgramReducer';
import capitalize from '@material-ui/core/utils/capitalize';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';
import { RouteMaster } from '../../Components/Routing';
import { BrancherButton } from '../../Components/InputFields/BrancherButton';
import { addPlural } from '../../utils/TextUtils';

const useStyles = makeStyles({
  dashboardCard: {
    maxHeight: (props: { desktop: boolean }) => (props.desktop ? 500 : 600),
    height: (props: { desktop: boolean }) => (props.desktop ? 500 : 600),
    maxWidth: 420,
    padding: 25,
    paddingBottom: 60,
  },
});

interface IProgramSummary {
  activeUsers: IActiveUsersData;
  applicationApproval?: IApplicationApprovalHealthData;
}

export const ProgramSummaryHealth: React.FC<IProgramSummary> = (props) => {
  const requiresApplicantApproval = useSelector(
    (state: IStoreTypes) => state.program?.[UpdatableProgramInfo.REQUIRES_APPLICATION_APPROVAL],
  );
  const roleLabels = useSelector((state: IStoreTypes) => state.program?.roleLabels);
  const desktop = useMediaQuery(useTheme().breakpoints.up('xl'));
  const { activeUsers, applicationApproval } = props;
  const styles = useStyles({ desktop });

  const MenteeAmount: React.FC = () => {
    if (!requiresApplicantApproval) {
      return (
        <>
          <Text variant="xl" display="inline" color="purple" fontWeight={700} align="center">
            {activeUsers?.totalMenteeAmount}{' '}
          </Text>
          <Text variant="xl" display="inline" fontWeight={600}>
            {capitalize(roleLabels[ProgramPositions.mentee])}
            {addPlural(applicationApproval.menteeNeedsReviewAmount)}
          </Text>
        </>
      );
    } else {
      return (
        <>
          <Text
            variant="md"
            display="inline"
            color="purple"
            fontWeight={600}
            marginBottom={20}
            align="center"
          >
            {activeUsers?.totalMenteeAmount - applicationApproval.menteeNeedsReviewAmount}{' '}
            {roleLabels[ProgramPositions.mentee]}
            {addPlural(
              activeUsers?.totalMenteeAmount - applicationApproval.menteeNeedsReviewAmount,
            )}{' '}
            approved{' '}
          </Text>
          {applicationApproval?.menteeNeedsReviewAmount > 0 && (
            <BrancherButton
              size="small"
              color="secondary"
              onClick={() => window.location.assign(`${RouteMaster.programUsers.path}?sp=true`)}
            >
              {applicationApproval.menteeNeedsReviewAmount} {roleLabels[ProgramPositions.mentee]}
              {addPlural(applicationApproval.menteeNeedsReviewAmount)} pending approval{' '}
            </BrancherButton>
          )}
        </>
      );
    }
  };

  const MentorAmount: React.FC = () => {
    if (!requiresApplicantApproval) {
      return (
        <>
          <Text variant="xl" display="inline" color="purple" fontWeight={700} align="center">
            {activeUsers?.totalMentorAmount}{' '}
          </Text>
          <Text variant="xl" display="inline" fontWeight={600}>
            {capitalize(roleLabels[ProgramPositions.mentor])}s
          </Text>
        </>
      );
    } else {
      return (
        <>
          <Text
            variant="md"
            display="inline"
            color="purple"
            fontWeight={600}
            marginBottom={20}
            marginTop={40}
            align="center"
          >
            {activeUsers?.totalMentorAmount - applicationApproval.mentorNeedsReviewAmount}{' '}
            {roleLabels[ProgramPositions.mentor]}
            {addPlural(
              activeUsers?.totalMentorAmount - applicationApproval.mentorNeedsReviewAmount,
            )}{' '}
            approved{' '}
          </Text>
          {applicationApproval.mentorNeedsReviewAmount > 0 && (
            <BrancherButton
              size="small"
              color="secondary"
              onClick={() => window.location.assign(`${RouteMaster.programUsers.path}?sp=true`)}
            >
              {applicationApproval.mentorNeedsReviewAmount} {roleLabels[ProgramPositions.mentor]}
              {addPlural(applicationApproval.mentorNeedsReviewAmount)} pending approval{' '}
            </BrancherButton>
          )}
        </>
      );
    }
  };

  return (
    <Grid container>
      <Grid container item>
        <BrancherGridCard
          container
          item
          direction="column"
          spacing={3}
          className={styles.dashboardCard}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item container justifyContent="center">
            <Text variant="lg" color="purple" fontWeight={700}>
              Program Summary
            </Text>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item container alignItems="center" justifyContent="center" xs={12}>
              <MenteeAmount />
            </Grid>
            <Grid item container alignItems="center" justifyContent="center" xs={12}>
              <MentorAmount />
            </Grid>
          </Grid>
        </BrancherGridCard>
      </Grid>
    </Grid>
  );
};
