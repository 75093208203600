import { IReducerType } from './ReducerType';

export const SAVE_REPORTING_DATA = 'SAVE_REPORTING_DATA';

export const ReportReducer = (state = initialState, action: IReducerType) => {
  switch (action.type) {
    case SAVE_REPORTING_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export enum EGenerateReport {
  USER_REPORT = 'USER_REPORT',
  PAIRING_REPORT = 'PAIRING_REPORT',
  ALL_TRAINING_REPORT = 'ALL_TRAINING_REPORT',
  PROFILE_REPORT = 'PROFILE_REPORT',
  PERSONALITY_VALUES_REPORT = 'PERSONALITY_VALUES_REPORT',
  PAIRING_SATISFACTION_REPORT = 'PAIRING_SATISFACTION_REPORT',
}

export interface IReportColumn {
  readableName: string;
  attributeName: string;
}

export interface IReportInfo {
  userReportData?: any[];
  userReportColumns?: IReportColumn[];
  pairingReportColumns?: IReportColumn[];
  pairingReportData?: any[];
  suggestedPairsReportData?: any[];
  suggestedPairsReportColumns?: IReportColumn[];
  unpairedReportData?: any[];
  unpairedReportColumns?: IReportColumn[];
  wrappedUpPairsReportData?: any[];
  wrappedUpPairsReportColumns?: IReportColumn[];
  groupsReportData?: any[];
  groupsReportColumns?: IReportColumn[];
  mentoringRequestsReportData?: any[];
  mentoringRequestsReportColumns?: IReportColumn[];
  allTrainingMenteeReportData?: any[];
  allTrainingMentorReportData?: any[];
  allTrainingMenteeReportColumns?: IReportColumn[];
  allTrainingMentorReportColumns?: IReportColumn[];
  profileReportData?: any[];
  profileReportColumns?: IReportColumn[];
  personalityValuesReportData?: any[];
  personalityValuesReportColumns?: IReportColumn[];
  pairingSatisfactionReportData?: any[];
  pairingSatisfactionReportColumns?: IReportColumn[];
}

const initialState: IReportInfo = {};
