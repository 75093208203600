import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import capitalize from '@material-ui/core/utils/capitalize';
import { Text } from './Text';
import { Colors } from '../../consts/colors';
import {
  EProgramStatus,
  Subscriptions,
  UpdatableProgramInfo,
} from '../../store/reducers/ProgramReducer';
import { ISimpleProgramHealthData } from '../../store/reducers/CompaniesReducer';

interface IProgramTile {
  programInfo: ISimpleProgramHealthData;
  selectProgram?: (programInfo: ISimpleProgramHealthData) => void;
}

const useStyles = makeStyles({
  programCard: {
    padding: 15,
    borderRadius: 12,
    minHeight: 450,
    boxShadow: `0px 5px 15px ${Colors.cardBorder}`,
    alignItems: 'center',
    marginBottom: 40,
    '&:hover': {
      border: (props: { programStatusColor: string }) => `4px solid ${props.programStatusColor}`,
      cursor: 'pointer',
      '& .MuiTypography-root': {
        fontWeight: 600,
      },
    },
    border: (props: { programStatusColor: string }) => `2px solid ${props.programStatusColor}`,
  },
});

export const ProgramTile: React.FC<IProgramTile> = (props) => {
  const { programInfo, selectProgram } = props;
  const roleLabels = programInfo.roleLabels;
  const programStatus =
    programInfo.launchedProgram && programInfo.active
      ? EProgramStatus.ACTIVE
      : programInfo.launchedProgram &&
        !programInfo.active &&
        !programInfo.subscription?.includes(Subscriptions.ADHOC_PAIRING)
      ? EProgramStatus.ENDED
      : EProgramStatus.STARTED;
  const programStatusColor =
    programStatus === EProgramStatus.STARTED
      ? Colors.black
      : programStatus === EProgramStatus.ENDED
      ? Colors.disabledGrey
      : Colors.green;
  const styles = useStyles({ programStatusColor });

  // const userSubscriptionAmount = programInfo?.[UpdatableProgramInfo.MAXIMUM_USER_CAP_SOFT] ?? programInfo?.[UpdatableProgramInfo.MAXIMUM_USER_CAP_HARD] ?? 0;
  // const subscriptionHealth = (userSubscriptionAmount === programInfo.signedUpUserAmount) ? `Current subscription has no seats available` :
  //   (userSubscriptionAmount > programInfo.signedUpUserAmount) ? `${userSubscriptionAmount - programInfo.signedUpUserAmount} user seats available in current subscription` :
  //   `${programInfo.signedUpUserAmount - userSubscriptionAmount} users over current subscription`;

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent="center"
      alignItems="center"
      className={styles.programCard}
      onClick={() => selectProgram(programInfo)}
    >
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <Text
          variant="md"
          color={
            programStatus === EProgramStatus.STARTED
              ? 'black'
              : programStatus === EProgramStatus.ACTIVE
              ? 'green'
              : 'disabledGrey'
          }
          fontWeight={500}
        >
          {programInfo.programName}
        </Text>
      </Grid>
      <Grid item xs={10} sm={8} container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Text variant="md" display="inline" fontWeight={500}>
            Mentees:
          </Text>
        </Grid>
        <Grid item>
          <Text variant="lg" display="inline" fontWeight={600}>
            {programInfo.menteeAmount}
          </Text>
        </Grid>
      </Grid>
      <Grid item xs={10} sm={8} container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Text variant="md" display="inline" fontWeight={500}>
            Mentors:
          </Text>
        </Grid>
        <Grid item>
          <Text variant="lg" display="inline" fontWeight={600}>
            {programInfo.mentorAmount}
          </Text>
        </Grid>
      </Grid>
      <Grid item xs={10} sm={8} container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Text variant="md" display="inline" fontWeight={500}>
            Pairs:
          </Text>
        </Grid>
        <Grid item>
          <Text variant="lg" display="inline" fontWeight={600}>
            {programInfo.pairAmount}
          </Text>
        </Grid>
      </Grid>
      <Grid item xs={10} sm={8} container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Text variant="md" display="inline" fontWeight={500}>
            Unpaired Mentees:
          </Text>
        </Grid>
        <Grid item>
          <Text variant="lg" display="inline" fontWeight={600}>
            {programInfo.unpairedMenteeAmount ?? '0'}
          </Text>
        </Grid>
      </Grid>
      <Grid item xs={10} sm={8} container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Text variant="md" display="inline" fontWeight={500}>
            {capitalize(roleLabels.programStyle)} Spots Available:
          </Text>
        </Grid>
        <Grid item>
          <Text variant="lg" display="inline" fontWeight={600}>
            {programInfo.availableMentorCapacity ?? '0'}
          </Text>
        </Grid>
      </Grid>
      {programInfo[UpdatableProgramInfo.SUBSCRIPTION].includes(Subscriptions.GROUPS) && (
        <Grid item xs={10} sm={8} container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Text variant="md" display="inline" fontWeight={500}>
              Groups:
            </Text>
          </Grid>
          <Grid item>
            <Text variant="lg" display="inline" fontWeight={600}>
              {programInfo.groupsAmount}
            </Text>
          </Grid>
        </Grid>
      )}
      {programInfo?.[UpdatableProgramInfo.REQUIRES_APPLICATION_APPROVAL] && (
        <Grid item xs={10} sm={8} container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Text variant="md" display="inline" fontWeight={500}>
              To review:
            </Text>
          </Grid>
          <Grid item>
            <Text variant="lg" display="inline" fontWeight={600}>
              {programInfo.actionAmount}
            </Text>
          </Grid>
        </Grid>
      )}
      {/*<Grid item xs={10} container alignItems="flex-end" justifyContent="center">*/}
      {/*  <Text variant="xs" color="secondaryGrey" fontWeight={400} marginTop={20}>*/}
      {/*    {subscriptionHealth}*/}
      {/*  </Text>*/}
      {/*</Grid>*/}
    </Grid>
  );
};
