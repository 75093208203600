import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import LinkIcon from '@material-ui/icons/Link';
import { Page } from '../../Components/General/Page';
import { Text } from '../../Components/General/Text';
import { BrancherGridCard } from '../../Components/General/BrancherGridCard';

const useStyles = makeStyles({
  link: {
    width: '100%',
  },
  paddings: {
    minWidth: 280,
    minHeight: 150,
    padding: 20,
  },
});

export const Resources: React.FC = () => {
  const styles = useStyles();

  const sources = [
    {
      title: 'Deactivate User',
      src: 'https://scribehow.com/shared/Admin_Deactivate_User__ohDPjqdlTLWOD9K9o7bsDA',
    },
    {
      title: 'Deactivate User via CSV Upload',
      src: 'https://scribehow.com/shared/Admin_Deactivate_users_via_CSV_upload__Eltx-8DwR_Stmt-G9iZ4Cg',
    },
    {
      title: 'Survey reminders, reports, closing',
      src: 'https://scribehow.com/shared/Admin_survey_reminders_report_close__4WaCcuDqRfecv6nOIGFFzg',
    },
    {
      title: 'Download survey results',
      src: 'https://scribehow.com/shared/Admin_Download_Survey_Results__a_TNjStuSkyyymvYwKBK-A',
    },
    {
      title: "Remove a user's application and profile data",
      src: 'https://scribehow.com/shared/Admin_Remove_a_Users_Application_Form_Details_delete_all_data__Sk9w0qe0R8WQt8j2XJLwdA',
    },
  ];

  return (
    <Page marginBottom={40}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid container item xs={11}>
          <Text variant="xl" marginTop={30} marginBottom={20} color="purple">
            Resources
          </Text>
        </Grid>

        <Grid container item xs={11} spacing={5} justifyContent="space-between">
          {sources.map((resource, index) => (
            <Grid
              container
              item
              xs={12}
              md={6}
              justifyContent="center"
              alignItems="center"
              key={index}
            >
              <a
                href={resource.src}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                <Grid container justifyContent="center" alignItems="center" item xs={10}>
                  <BrancherGridCard
                    item
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    className={styles.paddings}
                    hoverCursor
                  >
                    <Grid item>
                      <Text variant="sm" fontWeight={600}>
                        {resource.title}
                      </Text>
                    </Grid>
                    <Grid item>
                      <LinkIcon />
                    </Grid>
                  </BrancherGridCard>
                </Grid>
              </a>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Page>
  );
};
