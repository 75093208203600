import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import capitalize from '@material-ui/core/utils/capitalize';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import { IActiveUsersData } from '../../store/reducers/DashboardReducer';
import { Text } from '../../Components/General/Text';
import { BrancherGridCard } from '../../Components/General/BrancherGridCard';
import { addPlural } from '../../utils/TextUtils';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { RouteMaster } from '../../Components/Routing';
import { IStoreTypes } from '../../store/storeTypes';

const useStyles = makeStyles({
  gridCard: {
    maxHeight: (props: { desktop: boolean }) => (props.desktop ? 500 : 600),
    height: (props: { desktop: boolean }) => (props.desktop ? 500 : 600),
    maxWidth: 420,
    padding: 25,
  },
});

interface IPairingHealth {
  activeUsers: IActiveUsersData;
}

export const PairingHealth: React.FC<IPairingHealth> = (props) => {
  const roleLabels = useSelector((state: IStoreTypes) => state.program.roleLabels);
  const desktop = useMediaQuery(useTheme().breakpoints.up('xl'));
  const { activeUsers } = props;
  const styles = useStyles({ desktop });

  const hasActivePairs: boolean = activeUsers?.activePairs !== undefined;

  return (
    <Grid container item>
      <Grid item container>
        {hasActivePairs && (
          <BrancherGridCard
            container
            item
            direction="column"
            spacing={3}
            className={styles.gridCard}
            alignItems="center"
            justifyContent="space-around"
          >
            <Grid container justifyContent="center" alignItems="flex-start">
              <Text variant="lg" color="purple" fontWeight={700} marginBottom={20} align="center">
                Pairs
              </Text>
            </Grid>
            <Grid item container justifyContent="center">
              <Text variant="lg" display="inline" color="purple" fontWeight={700}>
                {activeUsers?.activePairs}{' '}
              </Text>
              <Text variant="lg" display="inline" fontWeight={500}>
                Current Pair{addPlural(activeUsers?.activePairs)}
              </Text>
            </Grid>
            <Grid item container justifyContent="center">
              <Text variant="lg" display="inline" color="purple" fontWeight={700}>
                {activeUsers?.wrappedUpPairs}{' '}
              </Text>
              <Text variant="lg" display="inline" align="center" fontWeight={500}>
                Wrapped-up Pair{addPlural(activeUsers?.wrappedUpPairs)}
              </Text>
            </Grid>

            <Grid item container justifyContent="center">
              <BrancherDivider height={2} />
            </Grid>

            <Grid item container justifyContent="center">
              <BrancherLinkText
                href={`${RouteMaster.programUsers.path}?ume=true`}
                underline="always"
                variant="lg"
                display="inline"
                color="purple"
                fontWeight={700}
              >
                {activeUsers?.unpairedMenteeAmount}{' '}
              </BrancherLinkText>
              <Text variant="lg" display="inline" align="center" fontWeight={500}>
                Unpaired Mentee{addPlural(activeUsers?.unpairedMenteeAmount)}
              </Text>
            </Grid>
            <Grid item container justifyContent="center">
              <BrancherLinkText
                href={RouteMaster.matching.path}
                underline="always"
                variant="lg"
                display="inline"
                color="purple"
                fontWeight={700}
              >
                {activeUsers?.availableMentorCapacity}{' '}
              </BrancherLinkText>
              <Text variant="lg" display="inline" align="center" fontWeight={500}>
                {capitalize(roleLabels.programStyle)} Spots Available
              </Text>
            </Grid>
          </BrancherGridCard>
        )}
      </Grid>
    </Grid>
  );
};
