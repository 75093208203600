import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { ActionButton } from '../../Components/InputFields/BrancherButton';
import { GenerateStructuredPairingReport } from './GenerateStructuredPairingReport';
import { IStoreTypes } from '../../store/storeTypes';
import { BrancherDispatch, UtilGenerateReport } from '../../store/actions';
import { EGenerateReport } from '../../store/reducers/ReportReducer';
import { Subscriptions } from '../../store/reducers/ProgramReducer';
import { GenerateMoDPairingReport } from './GenerateMoDPairingReport';

interface IPairingReport {
  controlled?: boolean;
  completedCallback?: () => void;
}

export const PairingReport: React.FC<IPairingReport> = ({ controlled, completedCallback }) => {
  const programName = useSelector((state: IStoreTypes) => state.program.programName);
  const pairingColumns = useSelector((state: IStoreTypes) => state?.report?.pairingReportColumns);
  const pairingData = useSelector((state: IStoreTypes) => state?.report?.pairingReportData);
  const suggestedPairsData =
    useSelector((state: IStoreTypes) => state?.report?.suggestedPairsReportData) ?? [];
  const suggestedPairsColumns =
    useSelector((state: IStoreTypes) => state?.report?.suggestedPairsReportColumns) ?? [];
  const unpairedData = useSelector((state: IStoreTypes) => state?.report?.unpairedReportData) ?? [];
  const unpairedColumns =
    useSelector((state: IStoreTypes) => state?.report?.unpairedReportColumns) ?? [];
  const wrappedUpPairsReportData =
    useSelector((state: IStoreTypes) => state?.report?.wrappedUpPairsReportData) ?? [];
  const wrappedUpPairsReportColumns =
    useSelector((state: IStoreTypes) => state?.report?.wrappedUpPairsReportColumns) ?? [];
  const mentoringRequestsReportData =
    useSelector((state: IStoreTypes) => state?.report?.mentoringRequestsReportData) ?? [];
  const mentoringRequestsReportColumns =
    useSelector((state: IStoreTypes) => state?.report?.mentoringRequestsReportColumns) ?? [];
  const groupsReportData =
    useSelector((state: IStoreTypes) => state?.report?.groupsReportData) ?? [];
  const groupsReportColumns =
    useSelector((state: IStoreTypes) => state?.report?.groupsReportColumns) ?? [];
  const isAdhocPairing = useSelector((state: IStoreTypes) => state.program?.subscription)?.includes(
    Subscriptions.ADHOC_PAIRING,
  );
  const [generatingPairingReport, setGeneratingPairingReport] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (controlled) {
      generatePairingReport();
    }
  }, []);

  const generatePairingReport = () => {
    setGeneratingPairingReport(true);
    BrancherDispatch(
      dispatch,
      UtilGenerateReport(EGenerateReport.PAIRING_REPORT, () => {
        setGeneratingPairingReport(false);
        if (controlled) {
          completedCallback();
        }
      }),
    );
  };

  return (
    <Grid item xs={12} container alignItems="center" spacing={2}>
      {!controlled && (
        <Grid item xs={6}>
          <ActionButton
            onClick={generatePairingReport}
            loading={generatingPairingReport}
            size="small"
          >
            Generate Pairing report
          </ActionButton>
        </Grid>
      )}
      {pairingData &&
        (!isAdhocPairing ? (
          <Grid item xs={6}>
            <GenerateStructuredPairingReport
              data={pairingData}
              displayData={pairingColumns}
              suggestedPairsData={suggestedPairsData}
              suggestedPairsColumns={suggestedPairsColumns}
              unpairedColumns={unpairedColumns}
              unpairedData={unpairedData}
              groupsReportData={groupsReportData}
              groupsReportColumns={groupsReportColumns}
              wrappedUpPairsReportData={wrappedUpPairsReportData}
              wrappedUpPairsReportColumns={wrappedUpPairsReportColumns}
              programName={programName}
            />
          </Grid>
        ) : (
          <GenerateMoDPairingReport
            data={pairingData}
            displayData={pairingColumns}
            wrappedUpPairsReportData={wrappedUpPairsReportData}
            wrappedUpPairsReportColumns={wrappedUpPairsReportColumns}
            groupsReportData={groupsReportData}
            groupsReportColumns={groupsReportColumns}
            mentoringRequestsReportData={mentoringRequestsReportData}
            mentoringRequestsReportColumns={mentoringRequestsReportColumns}
            programName={programName}
          />
        ))}
    </Grid>
  );
};
