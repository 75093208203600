import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { SegmentedControlGroup } from '../../../Components/InputFields/BrancherSegmentedControl';
import { EChosenProfile, ReportingProfileOptions } from '../../Dashboard/Dashboard';
import { Text } from '../../../Components/General/Text';
import {
  EComponentType,
  IFormConfig,
  IGeneralQuestionsConfig,
} from '../../../store/reducers/ProgramReducer';
import { QuestionTile } from '../../../Components/General/QuestionTile';
import { QuestionCreatorBar } from '../../../Components/General/QuestionCreatorBar';
import { BrancherTile } from '../../../Components/General/BrancherTile';

interface IQuestionList {
  questions: IFormConfig['formConfig'];
  setSelectedQuestion: (questionName: string) => void;
  selectedQuestion: string;
  addQuestion: (index: number) => void;
  deleteQuestion: (questionName: string) => void;
}

export const QuestionList: React.FC<IQuestionList> = (props) => {
  const { questions, setSelectedQuestion, addQuestion, selectedQuestion, deleteQuestion } = props;
  const [chosenProfile, setChosenProfile] = React.useState<EChosenProfile>(EChosenProfile.ALL);

  const filteredGeneralQuestions =
    chosenProfile === EChosenProfile.MENTOR
      ? questions.general.filter((a) => a.isMentor)
      : chosenProfile === EChosenProfile.MENTEE
      ? questions.general.filter((a) => a.isMentee)
      : questions.general;

  return (
    <Grid container>
      <Grid item xs={11}>
        <SegmentedControlGroup
          value={chosenProfile}
          updateValue={(v: EChosenProfile) => setChosenProfile(v)}
          options={ReportingProfileOptions}
          fullWidth
        />
      </Grid>
      <Grid item xs={11} container spacing={3}>
        <Grid item xs={12}>
          <BrancherTile mb={-3}>
            <Text variant="sm" fontWeight={500}>
              Please complete this application form to help us understand more about you...
            </Text>
          </BrancherTile>
        </Grid>
        <Grid item xs={12}>
          <QuestionCreatorBar addQuestion={() => addQuestion(0)} />
        </Grid>
        {filteredGeneralQuestions?.map((f, i) => (
          <Grid item xs={12} key={i}>
            <Box onClick={() => setSelectedQuestion(f.name)}>
              <QuestionTile
                hoverCursor
                question={f}
                selected={f.name === selectedQuestion}
                questionIndex={i}
                deleteQuestion={() => deleteQuestion(f.name)}
              />
            </Box>
            <QuestionCreatorBar addQuestion={() => addQuestion(i + 1)} />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Box>
            <QuestionTile
              question={
                {
                  question: `Values (${
                    questions?.values?.[0]?.options?.length
                      ? questions?.values?.length * questions?.values?.[0]?.options?.length
                      : questions?.values?.items?.length
                  } short questions)`,
                  isMentee: true,
                  isMentor: true,
                  componentType: EComponentType.SELECTION,
                } as IGeneralQuestionsConfig
              }
              selected={false}
              questionIndex={filteredGeneralQuestions.length}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <QuestionTile
              question={
                {
                  question: `Personality (${
                    questions.personality?.[0]?.options
                      ? questions.personality.length * questions.personality?.[0]?.options?.length
                      : questions.personality?.options?.length
                  } short questions)`,
                  isMentee: true,
                  isMentor: true,
                  componentType: EComponentType.SELECTION,
                } as IGeneralQuestionsConfig
              }
              selected={false}
              questionIndex={filteredGeneralQuestions.length + 1}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <BrancherTile>
            <Text variant="sm" fontWeight={500}>
              Great thanks. Now just a few questions for reporting purposes.
            </Text>
          </BrancherTile>
        </Grid>
        {questions.finalising?.map((f, i) => (
          <Grid item xs={12} key={i}>
            <Box>
              <QuestionTile
                question={{ ...f, isMentee: true, isMentor: true }}
                selected={f.name === selectedQuestion}
                questionIndex={filteredGeneralQuestions.length + 2 + i}
              />
            </Box>
          </Grid>
        ))}
        <Grid item xs={12}>
          <BrancherTile>
            <Text variant="sm" fontWeight={500}>
              Congratulations! You have completed the application form. We'll be in touch soon.
            </Text>
          </BrancherTile>
        </Grid>
      </Grid>
    </Grid>
  );
};
