import { IReducerType } from './ReducerType';
import { IProgram } from './ProgramReducer';
import { IValidationRules } from '../../utils/validators/validatePassword';

export const SAVE_COMPANIES_DATA = 'SAVE_COMPANIES_DATA';

export const CompaniesReducer = (state = initialState, action: IReducerType) => {
  switch (action.type) {
    case SAVE_COMPANIES_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export enum UpdatableCompanyInfo {
  COMPANY_NAME = 'companyName',
  PROGRAM_IDS = 'programIds',
  GOOGLE_SSO = 'googleSignOn',
  SAML_SSO = 'samlSignOn',
  PASSWORD_AUTH = 'passwordSignOn',
  CUSTOM_LOGO = 'customLogo',
  WHITE_LABEL = 'whiteLabel',
  DOMAIN_EMAIL = 'domainEmail',
  PASSWORD_VALIDATION = 'passwordValidation',
  DISABLED = 'disabled',
}

export interface ICompany {
  companyId: string;
  [UpdatableCompanyInfo.COMPANY_NAME]: string;
  mfaEnabled?: boolean;
  [UpdatableCompanyInfo.PROGRAM_IDS]?: string[];
  [UpdatableCompanyInfo.GOOGLE_SSO]?: boolean;
  [UpdatableCompanyInfo.PASSWORD_AUTH]?: boolean;
  [UpdatableCompanyInfo.CUSTOM_LOGO]?: string;
  [UpdatableCompanyInfo.WHITE_LABEL]?: boolean;
  [UpdatableCompanyInfo.DISABLED]?: boolean;
  [UpdatableCompanyInfo.DOMAIN_EMAIL]?: string[];
  [UpdatableCompanyInfo.PASSWORD_VALIDATION]?: IValidationRules;
}

interface IExtendedCompanyProgramInfo extends ICompany {
  activeProgramAmount: number;
}

export interface ISimpleProgramHealthData extends IProgram {
  menteeAmount?: number;
  mentorAmount?: number;
  pairAmount?: number;
  signedUpUserAmount?: number;
  unpairedMenteeAmount?: number; // amount of mentees without a pair
  availableMentorCapacity?: number; // sum of mentor spots from all mentors pairingAmount minus amount of pairs
  actionAmount?: number; // for requires applicant approval on
  groupsAmount?: number; // for programs with groups on
}

export interface ICompaniesInfo {
  programs?: ISimpleProgramHealthData[];
  companyList?: IExtendedCompanyProgramInfo[];
}

const initialState: ICompaniesInfo = {};
