import { IReducerType } from './ReducerType';
import { IUserInfo } from './UserInfoReducer';
import { ProgramPositions } from '../../consts/ProgramPositionOptions';

export const SAVE_PAIRING_DATA = 'SAVE_PAIRING_DATA';
export const SAVE_DRAFTED_PAIRS = 'SAVE_DRAFTED_PAIRS';
export const SAVE_SUGGESTED_PAIRS = 'SAVE_SUGGESTED_PAIRS';
export const CLEAN_PAIRING_DATA = 'CLEAN_PAIRING_DATA';

export const PairingReducer = (state = initialState, action: IReducerType) => {
  switch (action.type) {
    case SAVE_PAIRING_DATA:
      return { ...state, ...action.payload };
    case SAVE_DRAFTED_PAIRS:
      return { ...state, draftPairs: action.payload };
    case SAVE_SUGGESTED_PAIRS:
      return { ...state, paired: action.payload };
    case CLEAN_PAIRING_DATA:
      return initialState;
    default:
      return state;
  }
};

export interface IPairReason {
  attributeName: string; // variable name - for personality and values. These are 'personality' and 'values'
  readableName: string;
  percentage: string;
  reasons: string[]; // For personality and values these are the attributes
}

export interface IPairingReasons {
  custom: IPairReason[];
  personality: IPairReason[];
  values: IPairReason[];
}

interface IExtraWrapUpAttributes {
  initiatorPosition: ProgramPositions; // position of the user who has wrapped up the relationship
  matchSatisfaction: number;
  wrapUpReasons: string;
  commentToPartner?: string;
  endingRelationshipExtraReasons?: string;
}

interface IExtraPartnerSurveyWrapUpAttributes {
  partnerMatchSatisfaction: number;
  partnerExtraComment?: string;
}

export interface IOldPairs
  extends IDraftedPairs,
    IExtraWrapUpAttributes,
    IExtraPartnerSurveyWrapUpAttributes {
  pairingEndDate?: number;
}

export interface IPairsInfo {
  name: string;
  roleId: string;
  pairingReasons: {
    custom: IPairReason[];
    personality: IPairReason[];
    values: IPairReason[];
  };
  programId: string;
  pairingStartDate?: number;
}

export enum EPairingTypes {
  BRANCHER_RECOMMENDED = 'BRANCHER_RECOMMENDED', // used Brancher's recommendation
  TARGET_USER_RECOMMENDED = 'TARGET_USER_RECOMMENDED', // targeted user and use Brancher's recommendation once targeted
  NO_ALGORITHM = 'NO_ALGORITHM', // target user and ignored algorithm
}

export interface IDraftedPairs extends Pick<IPairsInfo, 'pairingReasons' | 'pairingStartDate'> {
  approved?: boolean;
  mentee?: string;
  menteeName?: string;
  mentor?: string;
  mentorName?: string;
  mentorPairAmount?: number;
  menteeAccessibilityDetails?: string;
  mentorAccessibilityDetails?: string;
  mentorApprovedPairs?: number;
  menteePairAmount?: number;
  menteeApprovedPairs?: number;
  percentageMatch?: number;
  personalityScoreTotal?: string;
  valuesScoreTotal?: string;
  pairingType?: EPairingTypes;
  response: any;
}

export interface IUnmatched extends IUserInfo {
  approvedPairs?: number;
  pairAmount?: number;
}

export interface IPairingInfo {
  paired?: IDraftedPairs[];
  unpaired?: { [ProgramPositions.mentee]?: IUnmatched[]; [ProgramPositions.mentor]?: IUnmatched[] };
  draftPairs?: IDraftedPairs[];
  completedPairing?: boolean;
  pairingReportData?: any[];
  pairingReportColumns?: Array<{ readableName: string; attributeName: string }>;
  suggestedPairsReportData?: any[];
  suggestedPairsReportColumns?: Array<{ readableName: string; attributeName: string }>;
  unpairedReportData?: any[];
  unpairedReportColumns?: Array<{ readableName: string; attributeName: string }>;
}

const initialState: IPairingInfo = {
  draftPairs: [],
  paired: [],
  unpaired: {},
};
